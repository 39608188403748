import { createSlice } from '@reduxjs/toolkit';
import { accessAccountUser, loginUser } from './authActions';

const sessionId = localStorage.getItem('sessionId');
const isDarkTheme = localStorage.getItem('theme');

const initialState = {
    isLoggedIn: sessionId ? true : false,
    isDark: isDarkTheme == 'dark' ? true : false,
    menuItem: [],
    loadingMenuItems: true,
    loading: false,
    error: false,
    success: false,
    loadingAccess: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLogging: (state) => {
            state.isLoggedIn = true;
        },
        userLoggingOut: () => {
            localStorage.clear();
            return {
                isLoggedIn: false,
                isDark: false,
                menuItem: [],
                loadingMenuItems: false,
                loading: false,
                error: false,
                success: false,
                loadingAccess: false
            };
        },
        addMenuItem: (state, { payload }) => {
            state.menuItem = payload;
        },
        loadMenuItem: (state, { payload }) => {
            state.loadingMenuItems = payload;
        },
        changeTheme: (state) => {
            state.isDark = !state.isDark;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(loginUser.fulfilled, (state, { payload }) => {
                state.error = false;

                const userInfo = payload?.adminlogin?.user;

                if (userInfo) {
                    const userSessionId = userInfo.id;

                    localStorage.setItem('sessionId', userSessionId);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    state.isLoggedIn = true;
                } else {
                    state.error = payload?.adminlogin?.message;
                    state.loading = false;
                }
            })
            .addCase(loginUser.rejected, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(accessAccountUser.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
            });
    }
});

export const { userLoggingOut, userLogging, addMenuItem, loadMenuItem, changeTheme } = authSlice.actions;

export default authSlice.reducer;
