import { useRoutes } from 'react-router-dom';

// routes
import AuthRoutes from './AuthRoutes';
import MainRoutes, { NotFoundRoutes } from './MainRoutes';
import { useSelector } from 'react-redux';

export default function Routes() {
    const { isLoggedIn } = useSelector((state: any) => state.auth);

    const currentRoutes = isLoggedIn ? MainRoutes : AuthRoutes;

    return useRoutes([NotFoundRoutes, currentRoutes]);
}
