import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import AuthGuard from 'common/auth-guard';
import Loadable from 'components/common/UI/Loadable';

const StateOfMatchingPage = Loadable(lazy(() => import('views/dashboard/StateOfMatchingView/StateOfMatchingPage')));
const UsersPage = Loadable(lazy(() => import('views/dashboard/UsersPage/UsersPage')));
const AccessesPage = Loadable(lazy(() => import('views/dashboard/AccessesPage/AccessesPage')));
const Profil = Loadable(lazy(() => import('views/settings/Profil')));
const NotFoundPage = Loadable(lazy(() => import('views/settings/NotFoundPage')));
const AccountsPage = Loadable(lazy(() => import('views/dashboard/AccountsPage/AccountsPage')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <UsersPage title="Users Management | Suite Dashboard" />
        },
        {
            path: '/access',
            element: <AccessesPage title="Access Managements | Suite Dashboard" />
        },
        {
            path: '/accounts',
            element: <AccountsPage title="Accounts Managements | Suite Dashboard" />
        },
        {
            path: '/settings/stats',
            element: <StateOfMatchingPage title="State of Matching | Suite Dashboard" />
        },
        {
            path: '/settings/profile',
            element: <Profil title="Profile - Suite 360" />
        },
        {
            path: '/login',
            element: <Navigate to="/" />
        },
        {
            path: '/register',
            element: <Navigate to="/" />
        },
        {
            path: '/forgot',
            element: <Navigate to="/" />
        },
        {
            path: '/new-password',
            element: <Navigate to="/" />
        }
    ]
};

export const NotFoundRoutes = {
    path: '*',
    element: <NotFoundPage title="Page not found | Suite Dashboard" />
};

export default MainRoutes;
