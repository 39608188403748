import { Box, ListItemButton, ListItemIcon, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { FiSliders } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userLoggingOut } from 'store/slices/auth/authSlice';

import { useAppSelector } from 'store/hooks';
import MenuItemLoading from 'components/common/skeletons/MenuItemLoading';

interface SettingsPropoverProps {
    anchorEl: Element | ((element: Element) => Element) | null | undefined | any;
    handleClose: () => void;
    open: boolean;
}

function SettingsPropover({ anchorEl, handleClose, open }: SettingsPropoverProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingMenuItems } = useAppSelector((state) => state.auth);

    const handleLogOut = () => {
        handleClose;
        dispatch(userLoggingOut());
        navigate('/');
    };

    return (
        <Popover
            id="account-settings-popover"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <MenuList
                disablePadding
                dense
                sx={{
                    p: 1,
                    '& > *': {
                        borderRadius: 2
                    }
                }}
            >
                {loadingMenuItems ? (
                    <Box sx={{ width: 150 }}>
                        <MenuItemLoading height={20} radius={2} />
                    </Box>
                ) : (
                    <Box>
                        <ListItemButton onClick={handleLogOut}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography>Sign Out</Typography>
                        </ListItemButton>
                        <MenuItem
                            sx={{
                                mb: 1,
                                borderRadius: 1,
                                ...(location.pathname === '/settings/profile' && {
                                    color: 'white',
                                    backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                })
                            }}
                            selected={location.pathname === '/settings/profile'}
                            onClick={() => {
                                handleClose();
                                navigate('/settings/profile');
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    ...(location.pathname === '/settings/profile' && {
                                        color: 'white'
                                    })
                                }}
                            >
                                <FiSliders fontSize="small" />
                            </ListItemIcon>
                            <Typography>My Profile</Typography>
                        </MenuItem>
                    </Box>
                )}
            </MenuList>
        </Popover>
    );
}

export default SettingsPropover;
