import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from 'components/common/UI/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('views/authentification/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentification/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentification/ForgotPassword')));
const NewPassword = Loadable(lazy(() => import('views/authentification/NewPassword')));

const AuthRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <Navigate replace to="/" />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/new-password',
            element: <NewPassword />
        }
    ]
};

export default AuthRoutes;
