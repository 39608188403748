import { FiBarChart2 } from 'react-icons/fi';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';

export const sideBarItems = [
    {
        id: 'user-management',
        navlabel: true,
        subheader: 'User Management',
        icon: <FiBarChart2 size={20} />
    },
    {
        id: 'user-management',
        title: 'Users Management',
        path: '/',
        icon: <GroupAddOutlinedIcon />
    },
    {
        id: 'user-management',
        title: 'Access Creation',
        path: '/access',
        icon: <GppGoodOutlinedIcon />
    },
    {
        id: 'user-management',
        title: 'Accounts Creation',
        path: '/accounts',
        icon: <FeaturedPlayListOutlinedIcon />
    }
];
