import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACCESS_ACCOUNT_MUTATION } from 'services/authService/queries';
import appoloClient from './appolo-client';
import { useAppDispatch } from 'store/hooks';
import { userLoggingOut } from 'store/slices/auth/authSlice';
import { Box, Stack } from '@mui/system';
import Logo from 'components/common/UI/Logo';
import AppLoader from 'components/common/UI/AppLoader/AppLoader';

function AuthGuard({ children }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const { isLoggedIn, userInfo } = useSelector((state: any) => state.auth);

    const fetchUser = useCallback(async () => {
        try {
            const response = await appoloClient.query({
                query: ACCESS_ACCOUNT_MUTATION,
                variables: { user: userInfo?.id }
            });

            if (response.data && response.data.accesses.list) {
                const userData = response?.data?.accesses.list;

                if (!userData) {
                    dispatch(userLoggingOut());
                    navigate('/', { replace: true });
                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log('errors :', error);
            navigate('/', { replace: true });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            localStorage.clear();
            dispatch(userLoggingOut());
            navigate('/', { replace: true });
            setLoading(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        fetchUser();
    }, []);

    if (loading)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Stack spacing={5} direction="column">
                    <Logo width={'250px'} />
                    <AppLoader />
                </Stack>
            </Box>
        );

    return children;
}

export default AuthGuard;
