import { gql } from '@apollo/client';

export const DELETE_ACCESS_MUTATION = gql`
    mutation deleteAccess($id: String!) {
        deleteAccess(id: $id) {
            deleted
            message {
                message
                code
                type
            }
            errors
        }
    }
`;

export const ACCESSES_QUERY = gql`
    query accesses($limit: Float, $page: Float) {
        accesses(limit: $limit, page: $page) {
            list {
                id
                user {
                    id
                    username
                    email
                    admin
                    acceptPrivacy
                }
                account {
                    id
                    name
                    config
                    website {
                        id
                        alias
                        name
                        url
                    }
                    websites {
                        id
                        alias
                        name
                        url
                    }
                    components {
                        name
                        allowed
                    }
                }
            }
        }
    }
`;

export const USERS_QUERY = gql`
    query users($limit: Float) {
        users(limit: $limit) {
            total
            list {
                id
                username
                email
                admin
                acceptPrivacy
            }
        }
    }
`;

export const ACOUNTS_QUERY = gql`
    query getaccounts($limit: Int, $page: Int) {
        getaccounts(limit: $limit, page: $page) {
            total
            list
        }
    }
`;

export const WEBSITES_QUERY = gql`
    query websites {
        websites {
            id
            alias
            name
            url
            country
            language
            data
        }
    }
`;

export const DELETE_ACCOUNT_MUTATION = gql`
    mutation deleteAccount($id: String!) {
        deleteAccount(id: $id) {
            deleted
            message {
                message
                code
                type
            }
            errors
        }
    }
`;

export const NEW_ACCOUNT_MUTATION = gql`
    mutation createNewAccount($account: JSON!) {
        createNewAccount(account: $account) {
            created
            message {
                message
                code
                type
            }
            errors
        }
    }
`;

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($id: String!, $accesses: String) {
        deleteUser(id: $id, accesses: $accesses) {
            message {
                message
                code
                type
            }
            errors
            user {
                id
                username
                email
            }
        }
    }
`;

export const ADD_USER_MUTATION = gql`
    mutation createUser($user: UserInput!, $sendEmail: Boolean) {
        createUser(user: $user, sendEmail: $sendEmail) {
            message {
                message
                type
                code
            }
            user {
                id
                username
                email
            }
            errors
        }
    }
`;

export const ADD_NEW_USER_ACCESS_MUTATION = gql`
    mutation createNewUserAccess($user: String!, $accounts: String!) {
        createNewUserAccess(user: $user, accounts: $accounts) {
            message
            created
            errors
        }
    }
`;

export const SIGNUP_MUTATION = gql`
    mutation adminlogin($key: String!, $password: String!) {
        adminlogin(loginInput: { key: $key, password: $password }) {
            message {
                message
            }
            user {
                id
                username
                email
                resetToken
                acceptPrivacy
            }
        }
    }
`;

export const LOGOUT_MUTATION = gql`
    mutation login($key: String!, $password: String!) {
        login(loginInput: { key: $key, password: $password }) {
            message {
                message
            }
            user {
                id
                username
                email
                resetToken
                acceptPrivacy
            }
        }
    }
`;

export const ACCESS_ACCOUNT_MUTATION = gql`
    query access($user: String) {
        accesses(user: $user) {
            list {
                id
                user {
                    id
                    username
                    email
                    resetToken
                    acceptPrivacy
                }
                account {
                    id
                    name
                    config
                    websites {
                        id
                        alias
                        name
                        url
                        data
                    }
                    website {
                        id
                        alias
                        name
                        data
                        url
                    }
                    components {
                        name
                        allowed
                    }
                }
            }
        }
    }
`;

export const USER_SESSION_MUTATION = gql`
    query SessionUser {
        SessionUser {
            id
            username
            email
        }
    }
`;

export const CHECK_PRIVACY_ACCEPT = gql`
    query checkPrivacyAccept($user: String!) {
        checkPrivacyAccept(user: $user) {
            accepted
            error
        }
    }
`;

export const ADD_USER_ACCEPT_PRIVACY = gql`
    mutation addUserAcceptPrivacy($user: String!, $ip: String, $address: String) {
        addUserAcceptPrivacy(user: $user, ip: $ip, address: $address) {
            accepted
            changed
            error
        }
    }
`;
